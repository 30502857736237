import moment from 'moment-timezone'

import { timezone } from 'config/timezone'

moment.tz.setDefault(timezone)

export const FORMAT_DAY_MONTH_YEAR_WITH_NAMES = 'dddd dd MMMM YYYY '
export const FORMAT_DAY_MONTH_YEAR = 'DD-MM-YYYY'
export const FORMAT_DAY_MONTH_YEAR_DETAILS = 'DD-MM-YYYY, h:mm:ss a'
export const FORMAT_DAY_MONTH_YEAR_DETAILS_SHORT = 'DD/MM/YYYY, h:mm a'
export const FORMAT_DAY_MONTH_YEAR_LONG = 'dddd DD MMMM YYYY'
export const FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD'
export const FORMAT_YEAR_SHORT_MONTH_DAY = 'YY-MM-DD'
export const FORMAT_TIME = 'h:mm:ss a'

export function calculateStartAndEndDateFromUnix(date: number): {
  endDate: number
  startDate: number
} {
  const startDate = calculateStartDateFromUnix(date)
  const endDate = calculateEndDateFromUnix(date)

  return {
    endDate,
    startDate,
  }
}

export function calculateStartDateFromUnix(date: number): number {
  return moment(
    moment.unix(date).startOf('day').format(FORMAT_DAY_MONTH_YEAR_DETAILS),
    FORMAT_DAY_MONTH_YEAR_DETAILS,
  ).unix()
}

export function calculateEndDateFromUnix(date: number): number {
  return moment(
    moment.unix(date).endOf('day').format(FORMAT_DAY_MONTH_YEAR_DETAILS),
    FORMAT_DAY_MONTH_YEAR_DETAILS,
  ).unix()
}

export function convertFrom24To12Format(time24: string): string {
  const match = time24.match(/([0-9]{1,2})([0-9]{2})/)

  if (match) {
    const [sHours, minutes] = match.slice(1)
    const period = +sHours < 12 ? 'AM' : 'PM'
    const hours = +sHours % 12 || 12

    return `${hours}:${minutes} ${period}`
  }

  return ''
}

export function convertToDateString(date: number): string {
  return moment.unix(date).format(FORMAT_DAY_MONTH_YEAR)
}

export async function calculateDiffUntilNow(
  timestamp: number,
): Promise<moment.Duration> {
  const currentTime = moment.now()
  const now = moment(currentTime)
  const duration = moment.duration(now.diff(moment(timestamp)))

  return duration
}

export function getEndOfWeekDate(date: number): string {
  return moment
    .unix(date)
    .startOf('isoWeek')
    .day(7)
    .hour(19)
    .minutes(28)
    .seconds(0)
    .format(FORMAT_DAY_MONTH_YEAR_DETAILS)
}
