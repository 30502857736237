import firebase from 'firebase/compat/app'

import { calculateStartAndEndDateFromUnix } from 'common/date-formats'
import { WinningNumber, WinningNumberMap } from 'types'

import { COLLECTION_WINNING_NUMBERS, db } from './firebase'

async function getWinningNumbersByDate(date: number) {
  const { endDate, startDate } = calculateStartAndEndDateFromUnix(date)
  const map: WinningNumberMap = new Map()
  const array: WinningNumber[] = []

  const querySnapshot: firebase.firestore.QuerySnapshot = await db
    .collection(COLLECTION_WINNING_NUMBERS)
    .where('drawDate', '>=', startDate)
    .where('drawDate', '<=', endDate)
    .orderBy('drawDate', 'desc')
    .orderBy('gameTypeName', 'desc')
    .get({ source: 'server' })

  if (querySnapshot.empty) {
    return { array, map }
  }

  const queryDocuments = querySnapshot.docs
  queryDocuments.forEach((doc: firebase.firestore.DocumentData): void => {
    const item = doc.data() as WinningNumber
    const number: WinningNumber = {
      ...item,
      key: doc.id,
    }

    if (
      !number.isTestMode &&
      (number.isGameTypeActive === undefined ||
        number.isGameTypeActive === true)
    ) {
      if (map.has(item.gameTypeKey)) {
        const numbers = map.get(item.gameTypeKey) || []
        map.set(item.gameTypeKey, numbers.concat([number]))
      } else {
        map.set(item.gameTypeKey, [number])
      }

      array.push(number)
    }
  })

  return { array, map }
}

export const ApiServiceWinningNumbers = {
  getWinningNumbersByDate,
}
