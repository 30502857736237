export const firebaseAppName = 'puntos-gana-mas'
export const firebaseConfig = {
  apiKey: 'AIzaSyBNs4xBhci64dJCd2sIjLeTTuNvyViRMa8',
  appId: '1:1064266049958:web:0007de22a837d996dc0767',
  authDomain: 'puntosganamas.firebaseapp.com',
  measurementId: 'G-Y5ZXWG9S0Z',
  messagingSenderId: '1064266049958',
  projectId: 'puntosganamas',
  storageBucket: 'puntosganamas.appspot.com',
}
